import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { Add, Delete } from '@mui/icons-material';
import { Button, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import { GetMyProvidersResponse } from 'api/endpoints/customer-provider/get-providers';
import { useAddUsers } from 'api/endpoints/meetings/add-users';
import { useCreateMeetings } from 'api/endpoints/meetings/create-meetings';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ConferenceUsersBlock } from 'components/conference-users-block';
import { PrivateCabinetHeader } from 'components/headers/private-cabinet-header';
import { SelectMembersModal } from 'components/modals/select-members-modal';
import { SelectDateAndTime } from 'components/select-date-and-time';
import { PRIVATE_CABINET } from 'constants/path';
import { Headset, User } from 'types';
import style from './create-сonference.module.scss';
import { HeadsetsModal } from './headsets-modal';
import { ProvidersModal } from './providers-modal';

dayjs.extend(utc);

enum TypeExpert {
  ElectronEngineer = 'electronics',
  Mechanic = 'mechanic',
  Someone = 'unknown',
}

enum TypeTime {
  RightNow = 'now',
  Today = 'today',
  ThreeDays = 'three_days',
  SelectDate = 'custom',
}

type Connection = GetMyProvidersResponse['results'][0] | null;
//type TFile = { id: number; file: File };

// TODO: внедрить форматирование даты и времени вместе
// пока это не столь важно, надо договариваться с бэком
/* // формат data - 'DD.MM.YYYY HH:mm'
const formatStandardDataToDayjs = (date: string) => {
  return dayjs(date, 'DD.MM.YYYY HH:mm');
};

// дата моего часового пояса -> дата пояса UTC+00:00
// формат data - 'DD.MM.YYYY HH:mm'
type GetUTCDateAndTime = { dateAndTime?: string; date?: string; time?: string };
const getUTCDateAndTime = ({ dateAndTime, date, time }: GetUTCDateAndTime) => {
  let dayjsDate = formatStandardDataToDayjs(date);
  const utc = dayjsDate.utc();
  const utcDate = utc.format('DD.MM.YYYY');
  const utcTime = utc.format('HH:mm');
  return { date: utcDate, time: utcTime };
}; */

export const CreateConference = () => {
  const navigate = useNavigate();
  const [equipmentName, setEquipmentName] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [typeExpert, setTypeExpert] = useState<TypeExpert | null>(null);
  const [typeTime, setTypeTime] = useState<TypeTime | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  //const [files, setFiles] = useState<TFile[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [selectedConnection, setSelectedConnection] = useState<Connection>(null);
  const [myMembers, setMyMembers] = useState<User[]>([]);
  const [headset, setHeadset] = useState<Headset | null>(null);
  const [isOpenMembersModal, setIsOpenMembersModal] = useState(false);
  const [isOpenProvidersModal, setIsOpenProvidersModal] = useState(false);
  const [isOpenHeadsetsModal, setIsOpenHeadsetsModal] = useState(false);

  const { trigger: createConference, isMutating: isMutatingConference } = useCreateMeetings();
  const { trigger: addUsers, isMutating: isMutatingUsers } = useAddUsers();

  useEffect(() => {
    return () => {
      setStartDate(null);
      setEndDate(null);
      setStartTime(null);
      setEndTime(null);
    };
  }, [typeTime]);

  const handleBack = () => {
    navigate(`${PRIVATE_CABINET}?type=active`);
  };

  const handleChangeTypeExpert = (value: string) => {
    setTypeExpert(value as TypeExpert);
  };

  const handleSelectTypeTime = (value: string) => {
    setTypeTime(value as TypeTime);
  };

  const handleChangeFiles: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { files } = e.currentTarget;
    /*  if (!files) return;
    const filesArr: TFile[] = [];
    for (let index = 0; index < files.length; index++) {
      const file = files.item(index);
      if (file) {
        filesArr.push({ id: Math.random(), file });
      }
    }
    setFiles((state) => [...state, ...filesArr]); */
    setFile(files?.[0] || null);
  };

  const onDrop = (files: File[]) => {
    /* if (!files) return;
    const filesArr: TFile[] = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (file) {
        filesArr.push({ id: Math.random(), file });
      }
    }
    setFiles((state) => [...state, ...filesArr]); */
    setFile(files?.[0] || null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const disabled =
    !selectedConnection ||
    !equipmentName ||
    !description ||
    !typeExpert ||
    !typeTime ||
    !location ||
    !myMembers ||
    !headset ||
    isMutatingConference ||
    isMutatingUsers;

  const handleSave = async () => {
    if (disabled) return;

    //const filesSend = files.map(item => item.file);

    try {
      const res = await createConference({
        headset: headset.id,
        customer_provider: selectedConnection.id,
        machine_serial: equipmentName,
        location: location,
        description: description,
        expert_type: typeExpert,
        time_choice: typeTime,
        planned_start_date: startDate?.format('DD.MM.YYYY'),
        planned_end_date: endDate?.format('DD.MM.YYYY'),
        planned_start_time: startTime?.utc()?.format('HH:mm'),
        planned_end_time: endTime?.utc()?.format('HH:mm'),
        //TODO: временное решение бэк должен добавить поддержку нескольких файлов
        files: file, //filesSend[0],
      });
      if (myMembers.length) {
        const ids = myMembers.map(item => item.id);
        await addUsers({ id: res.id, body: { user_ids: ids } });
      }
      toast.success('Конференция создана');
      handleBack();
    } catch (e) {
      console.error(e);
      toast.error(`${(e as { message: string }).message}`);
    }
  };

  const handleSaveMembers = (members: User[]) => {
    setMyMembers(members);
    setIsOpenMembersModal(false);
  };

  return (
    <>
      <div className={style.page}>
        {/* TODO: добавить звездочки на обязательные поля */}
        <PrivateCabinetHeader title='Новая конференция' onBack={handleBack} />
        <div className={style.content}>
          <TextField
            className={cn(style.item, style.item_fullWidth)}
            required
            sx={{
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }}
            label='Серийный номер станка'
            value={equipmentName}
            onChange={e => setEquipmentName(e.currentTarget.value)}
          />
          <TextField
            className={cn(style.item, style.item_fullWidth)}
            required
            sx={{
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }}
            label='Описание проблемы'
            multiline
            minRows={3}
            maxRows={5}
            value={description}
            onChange={e => setDescription(e.currentTarget.value)}
          />
          <TextField
            className={cn(style.item, style.item_fullWidth)}
            required
            sx={{
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }}
            label='Локация'
            value={location}
            onChange={e => setLocation(e.currentTarget.value)}
          />
          <div className={style.item}>
            <div className={style.label}>Файлы</div>
            {!file && (
              <div {...getRootProps()} className={cn(style.dropzone, { [style.dropzone__active]: isDragActive })}>
                <span>Выберите файл или перетащите его сюда</span>
                <input
                  style={{ display: 'none' }}
                  type='file'
                  //multiple
                  onChange={handleChangeFiles}
                  {...getInputProps()}
                />
              </div>
            )}
            {file && (
              <div className={style.filesBlock}>
                <div className={style.fileItem}>
                  <IconButton size='small' onClick={() => setFile(null)}>
                    <Delete color='error' />
                  </IconButton>
                  <div className={style.name}>{file.name}</div>
                </div>
              </div>
            )}
            {/* {!!files.length && (
              <div className={style.filesBlock}>
                {files.map(({ id, file }) => (
                  <div key={id} className={style.fileItem}>
                    <IconButton
                      size='small'
                      onClick={() => setFiles(state => state.filter(({ id: file_id }) => file_id !== id))}
                    >
                      <Delete color='error' />
                    </IconButton>
                    <div className={style.name}>{file.name}</div>
                  </div>
                ))}
              </div>
            )} */}
          </div>
          <div className={style.item}>
            <div className={style.label}>
              Тип необходимого эксперта<span className={style.star}>*</span>
            </div>
            <RadioGroup
              aria-labelledby='type-expert-label'
              name='type-expert'
              value={typeExpert}
              onChange={e => handleChangeTypeExpert(e.currentTarget.value)}
            >
              <FormControlLabel value={TypeExpert.ElectronEngineer} control={<Radio />} label='Эксперт электронщик' />
              <FormControlLabel value={TypeExpert.Mechanic} control={<Radio />} label='Эксперт механик' />
              <FormControlLabel value={TypeExpert.Someone} control={<Radio />} label='Не знаю какой эксперт' />
            </RadioGroup>
          </div>
          <div className={style.item}>
            <div className={style.label}>
              Желаемое время для техподдержки<span className={style.star}>*</span>
            </div>
            <RadioGroup
              aria-labelledby='type-time-label'
              name='type-time'
              value={typeTime}
              onChange={e => handleSelectTypeTime(e.currentTarget.value)}
            >
              <FormControlLabel value={TypeTime.RightNow} control={<Radio />} label='Горит - сейчас' />
              <FormControlLabel value={TypeTime.Today} control={<Radio />} label='Сегодня' />
              <FormControlLabel value={TypeTime.ThreeDays} control={<Radio />} label='В течении трех дней' />
              <FormControlLabel value={TypeTime.SelectDate} control={<Radio />} label='Выберите дату' />
            </RadioGroup>
          </div>
          {typeTime && typeTime !== TypeTime.RightNow && (
            <>
              <SelectDateAndTime
                required
                startDate={startDate}
                endDate={endDate}
                startTime={startTime}
                endTime={endTime}
                isVisibleDate={typeTime === TypeTime.SelectDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
              />
            </>
          )}
          <div>
            <ConferenceUsersBlock isHiddenOtherUsers required users={myMembers} />
            {/* TODO: добавить отображение участников */}
            {/* Отображать и разделять своих и противоположных */}
            {/* Реализовать редактирование этого списка (можно не только добавить, но и удалить) */}
            {/* Возможно кнопка будет называться подругому */}
            <Button startIcon={!myMembers.length && <Add />} onClick={() => setIsOpenMembersModal(true)}>
              {myMembers.length ? 'Заменить участников' : 'Добавить участников'}
            </Button>
          </div>
          <div className={style.item}>
            <div className={style.label}>
              Исполнитель<span className={style.star}>*</span>
            </div>
            <span style={{ display: 'block', marginBottom: '4px' }}>
              {selectedConnection?.provider_details?.company_name}
            </span>
            <Button onClick={() => setIsOpenProvidersModal(true)}>Выбрать исполнителя</Button>
          </div>
          <div className={style.item}>
            <div className={style.label}>
              Гарнитура<span className={style.star}>*</span>
            </div>
            <span style={{ display: 'block', marginBottom: '4px' }}>{headset?.name}</span>
            <Button onClick={() => setIsOpenHeadsetsModal(true)}>Выбрать гарнитуру</Button>
          </div>
          <div className={style.item}>
            <Button variant='contained' disabled={disabled} onClick={handleSave}>
              Создать
            </Button>
          </div>
        </div>
      </div>
      {isOpenMembersModal && (
        <SelectMembersModal onClose={() => setIsOpenMembersModal(false)} onSave={handleSaveMembers} />
      )}
      {isOpenProvidersModal && (
        <ProvidersModal setSelectedConnection={setSelectedConnection} onClose={() => setIsOpenProvidersModal(false)} />
      )}
      {isOpenHeadsetsModal && <HeadsetsModal setHeadset={setHeadset} onClose={() => setIsOpenHeadsetsModal(false)} />}
    </>
  );
};
