import { fetcher, Method, MEETINGS } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { ExpertType, Meeting, TypeChoice } from 'types';

type CreateMeetingsParams = {
  customer_provider: string;
  machine_serial: string;
  description: string;
  expert_type: ExpertType;
  time_choice: TypeChoice;
  location: string;
  headset: string;
  planned_start_date: string | undefined;
  planned_end_date: string | undefined;
  planned_start_time: string | undefined;
  planned_end_time: string | undefined;
  //TODO: временное решение бэк должен добавить поддержку нескольких файлов
  files: File | null;
};

type CreateMeetingsResponse = Meeting;

const createMeetings = async (path: string, { arg }: { arg: CreateMeetingsParams }) => {
  const formData = new FormData();
  Object.entries(arg).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value);
    }
  });
  return fetcher<CreateMeetingsResponse>({
    path,
    method: Method.POST,
    formData,
  });
};

export const useCreateMeetings = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(MEETINGS, createMeetings, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(MEETINGS));
    },
  });
};
