import React from 'react';
import { Button } from '@mui/material';
import { useGetHeadsets } from 'api';
import { TextLoader } from 'components/loaders';
import { Modal } from 'components/ui-kit';
import { Headset } from 'types';
import style from './providers-modal.module.scss';

type HeadsetsModalProps = {
  setHeadset: React.Dispatch<React.SetStateAction<Headset | null>>;
  onClose: () => void;
};

export const HeadsetsModal = ({ setHeadset, onClose }: HeadsetsModalProps) => {
  /* TODO: добавить инфинити скролл */
  const { data, isLoading } = useGetHeadsets({ limit: 50, offset: 0 });

  const selectHeadset = (headset: Headset) => {
    setHeadset(headset);
    onClose();
  };

  return (
    <Modal title='Выберите гарнитуру' onClose={onClose}>
      <div className={style.content}>
        {isLoading ? (
          <TextLoader />
        ) : data ? (
          data.results.map(item => (
            <div key={item.id} className={style.item} onClick={() => selectHeadset(item)}>
              {item.name}
            </div>
          ))
        ) : (
          'Список пуст'
        )}
      </div>
      <div className={style.buttons}>
        <Button fullWidth color='error' onClick={onClose}>
          Закрыть
        </Button>
      </div>
    </Modal>
  );
};
