import { MeetingStatus } from 'types';

export const ID_ROOT_MODAL_ELEMENT = 'root-modal-element';
export const ID_ROOT_NOTIFICATION_ELEMENT = 'root-notification-element';
export const ID_ROOT_TOOLTIP_ELEMENT = 'root-tooltip-element';

export enum UserRole {
  Admin = 'admin',
  Default = 'default',
}

export enum HeadsetVersion {
  v1_0 = 'v1.0',
  v2_0 = 'v2.0',
  v2_1 = 'v2.1',
}

export enum LoadingStatus {
  Loading = 'loading',
  Resolve = 'resolve',
  Rejact = 'rejact',
}

export enum DeviceWidth {
  Mobile = 940,
}

export const DEMO_USER_ID = '01937166-1f86-7add-bfc0-a06637d2d6f5';

export const ROLES_DATA = [
  { id: 'engineer', label: 'engineer' },
  { id: 'dispatcher', label: 'dispatcher' },
] as const;

export const ACTIVE_MEETING_STATUSES: MeetingStatus[] = ['pending', 'scheduled', 'in_progress'];
export const ARCHIVE_MEETING_STATUSES: MeetingStatus[] = ['draft', 'completed', 'cancelled'];
