import { useMemo } from 'react';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import style from './select-date-and-time.module.scss';

const formatDate = (date: Dayjs | null) => {
  if (!date) return '';
  return date.format('DD.MM.YYYY');
};

const formatTime = (date: Dayjs | null) => {
  if (!date) return '';
  return date.format('HH:mm');
};

type SelectDateAndTimeProps = {
  startDate: Dayjs | null;
  endDate?: Dayjs | null;
  startTime: Dayjs | null;
  endTime: Dayjs | null;
  isVisibleEndDate?: boolean;
  isVisibleDate?: boolean;
  isVisibleTime?: boolean;
  required?: boolean;
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setEndDate?: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setStartTime: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setEndTime: React.Dispatch<React.SetStateAction<Dayjs | null>>;
};
export const SelectDateAndTime = ({
  startDate,
  endDate,
  startTime,
  endTime,
  isVisibleEndDate = true,
  isVisibleDate = true,
  isVisibleTime = true,
  required,
  setStartDate,
  setEndDate,
  setStartTime,
  setEndTime,
}: SelectDateAndTimeProps) => {
  const dateString = useMemo(() => {
    if (!startDate || !endDate) return;
    let date = `Желаемое время: с ${formatDate(startDate)} до ${formatDate(endDate)}`;

    if (startTime && endTime) {
      let time = ` в ${formatTime(startTime)}-${formatTime(endTime)} часов`;
      date += time;
    }

    return date;
  }, [startDate, endDate, startTime, endTime]);

  return (
    <>
      {isVisibleDate && (
        <div className={style.item}>
          Дата{required && <span className={style.star}>*</span>}
          <div className={style.date}>
            <DatePicker label='От' maxDate={endDate ?? undefined} value={startDate} onChange={setStartDate} />
            {isVisibleEndDate && setEndDate && (
              <>
                <div className={style.line}>-</div>
                <DatePicker label='До' minDate={startDate ?? undefined} value={endDate} onChange={setEndDate} />
              </>
            )}
          </div>
        </div>
      )}
      {isVisibleTime && (
        <div className={style.item}>
          Время{required && <span className={style.star}>*</span>}
          <div className={style.date}>
            <TimePicker
              label='От'
              //timeSteps={{ hours: 1, minutes: 60, seconds: 60 }}
              maxTime={endTime ?? undefined}
              //format='HH:00'
              value={startTime}
              onChange={setStartTime}
            />
            <div className={style.line}>-</div>
            <TimePicker
              label='До'
              //timeSteps={{ hours: 1, minutes: 60, seconds: 60 }}
              minTime={startTime ?? undefined}
              //format='HH:00'
              value={endTime}
              onChange={setEndTime}
            />
          </div>
        </div>
      )}
      {dateString && (
        <div className={style.item} style={{ color: 'orange' }}>
          {dateString}
        </div>
      )}
    </>
  );
};
