export const AUTH = '/auth';
export const LOGIN = '/auth/login';
export const REGISTRATION = '/auth/registration';
export const SING_UP_EXPERT = '/auth/sing-up-expert';
export const SING_UP_ENGINEER = '/auth/sing-up-engineer';
export const PASSWORD_RESET = '/auth/password-reset';
export const CONFIRM_REGISTRATION = '/confirm-registration';
export const CONFIRM_RESET_PASSWORD = '/confirm-reset-password';
export const PRIVATE_CABINET = '/private-cabinet';
export const MEETING = '/private-cabinet/meeting';
export const FINISH_MEETING = '/private-cabinet/meeting/finish';
export const HEADSET_DETAILS = '/private-cabinet/headset-details';
export const EDIT_CONFERENCE = '/private-cabinet/edit-conference';
export const CONFERENCE_INFORMATION = '/private-cabinet/conference-information';
export const CREATE_CONFERENCE = '/private-cabinet/create-conference';
export const CONFERENCE = '/private-cabinet/conference';

export const QUERY = {
  headsetId: 'headset-id',
  conferenceId: 'conference-id',
  videoId: 'video-id',
  type: 'type',
};
