import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowUp } from 'react-icons/io';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useGetMe } from 'api';
import { ConfirmUserRegistration } from 'components/modals';
import { Button } from 'components/ui-kit';
import { PRIVATE_CABINET } from 'constants/path';
import style from './header.module.scss';
import { InformationBlock } from './information-block';

const ROLE_NAME: Record<string, string> = {
  admin: 'администратор',
  dispatcher: 'диспетчер',
  engineer: 'инженер',
};

export const Header = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'layouts.private-cabinet-layout' });
  const { data: user } = useGetMe();
  const buttonRef = useRef<HTMLDivElement>(null);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
  const [isShowInformation, setIsShowInformation] = useState(false);

  const handleOpenModalConfirm = () => setIsOpenModalConfirm(true);
  const handleCloseModalConfirm = () => setIsOpenModalConfirm(false);

  const handleToggleShowInformation = () => setIsShowInformation(state => !state);

  const handleClose = useCallback(() => setIsShowInformation(false), []);

  return (
    <>
      <div className={style.header}>
        <Link className={style.logo} to={PRIVATE_CABINET}>
          AVATAR
        </Link>
        {/* TODO: Delete */}
        {user?.is_verify === false && (
          <Button
            classNames={{ button: style.confirmButton }}
            label={t('activateAccount')}
            onClick={handleOpenModalConfirm}
          />
        )}
        {/*  */}
        <div className={style.userName} ref={buttonRef} onClick={handleToggleShowInformation}>
          <span className={style.role}>
            {t('user')} {user?.role ? ROLE_NAME[user.role] : ''}:&nbsp;
          </span>
          {user?.username ?? ''}
          <IoIosArrowUp className={cn(style.arrow, { [style.arrow__close]: !isShowInformation })} size='20' />
        </div>
        <InformationBlock isVisible={isShowInformation} buttonRef={buttonRef} onClose={handleClose} />
      </div>
      {isOpenModalConfirm && <ConfirmUserRegistration onClose={handleCloseModalConfirm} />}
    </>
  );
};
