import React, { FC, useMemo, useState } from 'react';
import { TIsAuthContext, IsAuthContext, ActionsIsAuthContext } from 'contexts';

type IsAuthProviderProps = {
  children: React.ReactNode;
};

export const IsAuthProvider: FC<IsAuthProviderProps> = ({ children }) => {
  const [isAuth, setIsAuth] = useState<TIsAuthContext['isAuth']>(false);
  const actions = useMemo(() => ({ setIsAuth }), []);
  return (
    <IsAuthContext.Provider value={{ isAuth }}>
      <ActionsIsAuthContext.Provider value={actions}>{children}</ActionsIsAuthContext.Provider>
    </IsAuthContext.Provider>
  );
};
