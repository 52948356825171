import React, { useState, useEffect, FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useGetHeadset, useGetMe } from 'api';
import { io, Socket } from 'socket.io-client';
import { useJanus } from 'hooks';
import { OtherContent } from 'pages/meeting/constants';
import { ControlsPanel, Content, SnapshotTaken } from 'pages/meeting/video-room';
import { LoadingNotification } from 'components/notifications';
import { getAuthorizationToken } from 'utils';
import { QUERY } from 'constants/path';
import style from './video-room.module.scss';

type VideoRoomProps = {
  isActiveMicrophone: boolean;
  isActiveVideo: boolean;
  videoDeviceId: string;
  audioDeviceId: string;
  isHasAccessMicrophone: boolean;
  isHasAccessVideo: boolean;
  myStream: MediaStream | null;
  setIsActiveMeeting: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActiveMicrophone: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActiveVideo: React.Dispatch<React.SetStateAction<boolean>>;
};

export const VideoRoom: FC<VideoRoomProps> = ({
  isActiveMicrophone,
  isActiveVideo,
  videoDeviceId,
  audioDeviceId,
  isHasAccessMicrophone,
  isHasAccessVideo,
  myStream,
  setIsActiveMeeting,
  setIsActiveMicrophone,
  setIsActiveVideo,
}) => {
  const [query] = useSearchParams();
  const headsetId = query.get(QUERY.headsetId);
  const conferenceId = query.get(QUERY.conferenceId);
  const { data: user } = useGetMe();
  const { data: headsetData } = useGetHeadset({ id: headsetId });
  const isMyHeadset = headsetId === user?.headset?.id;
  const displayName = (isMyHeadset ? headsetData?.key : user?.username) ?? '';

  const [socket, setSocket] = useState<Socket | null>(null);
  const [roomId, setRoomId] = useState(0);
  const [isFullscreen, setIsFullScreen] = useState(false);
  const [isShowVisualEffects, setIsShowVisualEffects] = useState(true);
  const [isTranslation, setIsTranslation] = useState(false);
  const [typeOtherContent, setIsTypeOtherContent] = useState<OtherContent | null>(null);
  const [selectStreamId, setSelectStreamId] = useState('');
  const [roomElement, setRoomElement] = useState<HTMLDivElement | null>(null);

  const { stream, pluginHandle, remoteStreams, setRemoteStreams } = useJanus({
    displayName,
    roomId,
    videoDeviceId,
    audioDeviceId,
    isHasAccessMicrophone,
    isHasAccessVideo,
    myStream,
    isMyHeadset,
    isRecordVideoDisable: !isMyHeadset,
  });

  useEffect(() => {
    if (!stream || !pluginHandle) return;
    if (isActiveVideo) {
      pluginHandle.unmuteVideo();
    } else {
      pluginHandle.muteVideo();
    }
  }, [stream, pluginHandle, isActiveVideo]);

  useEffect(() => {
    if (!stream || !pluginHandle) return;
    if (isActiveMicrophone) {
      pluginHandle.unmuteAudio();
    } else {
      pluginHandle.muteAudio();
    }
  }, [stream, pluginHandle, isActiveMicrophone]);

  useEffect(() => {
    if (isMyHeadset || !conferenceId) return;

    const token = getAuthorizationToken();
    const socket = io(`${process.env.REACT_APP_API_WEBSOCKET_URL}`, {
      path: '/ws/user/',
      query: { meeting: conferenceId },
      transports: ['websocket'],
      auth: {
        Authorization: token,
      },
      withCredentials: true,
    });
    setSocket(socket);

    socket.on('connect', () => {
      console.log('connect------USER');
    });
    socket.on('kick', publisherId => {
      setRemoteStreams(remoteStreams => {
        const newRemoteStreams = { ...remoteStreams };
        remoteStreams[publisherId]?.pluginHandle?.detach();
        delete newRemoteStreams[publisherId];
        return newRemoteStreams;
      });
    });

    socket.on('error', e => {
      console.error(e);
    });

    socket.on('room', room_id => {
      setRoomId(room_id);
    });

    return () => {
      socket.close();
      setSocket(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conferenceId, isMyHeadset]);

  useEffect(() => {
    if (!isMyHeadset || !headsetData) return;
    const socket = io(`${process.env.REACT_APP_API_WEBSOCKET_URL}`, {
      path: '/ws/headset/',
      transports: ['websocket'],
      withCredentials: true,
    });
    setSocket(socket);

    socket.on('connect', () => {
      console.log('connect------HEADSET');
    });

    const { uid, version, key } = headsetData;
    socket.emit('myid', { id: uid, ver: version, key });

    socket.on('room', room_id => {
      setRoomId(room_id);
    });

    socket.on('error', e => {
      console.error(e);
    });

    return () => {
      socket.close();
      setSocket(null);
    };
  }, [isMyHeadset, headsetData]);

  console.log('ROOM ROOM ROOM', socket);

  //TODO: чат должен быть изолирован от чата гарнитуры
  // это должны быть 2 разных чата

  return (
    <div className={cn(style.container)} ref={setRoomElement}>
      <Content
        socket={socket}
        userName={displayName ?? ''}
        stream={stream}
        remoteStreams={remoteStreams}
        typeOtherContent={typeOtherContent}
        isFullscreen={isFullscreen}
        headsetId={headsetId}
        isShowVisualEffects={isShowVisualEffects}
        isTranslation={isTranslation}
        selectStreamId={selectStreamId}
        setSelectStreamId={setSelectStreamId}
      />
      <ControlsPanel
        socket={socket}
        stream={stream}
        videoDeviceId={videoDeviceId}
        remoteStreams={remoteStreams}
        selectStreamId={selectStreamId}
        pluginHandle={pluginHandle}
        typeOtherContent={typeOtherContent}
        isFullscreen={isFullscreen}
        isHasAccessMicrophone={isHasAccessMicrophone}
        isHasAccessVideo={isHasAccessVideo}
        isActiveMicrophone={isActiveMicrophone}
        isActiveVideo={isActiveVideo}
        isShowVisualEffects={isShowVisualEffects}
        isTranslation={isTranslation}
        roomElement={roomElement}
        setIsTranslation={setIsTranslation}
        setIsFullScreen={setIsFullScreen}
        setIsShowVisualEffects={setIsShowVisualEffects}
        setIsTypeOtherContent={setIsTypeOtherContent}
        setIsActiveMeeting={setIsActiveMeeting}
        setPause={() => null}
        setIsActiveMicrophone={setIsActiveMicrophone}
        setIsActiveVideo={setIsActiveVideo}
      />
      <SnapshotTaken socket={socket} />
      <LoadingNotification socket={socket} />
    </div>
  );
};
