import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useGetHeadset, useGetMe } from 'api';
import { useGetMeeting } from 'api/endpoints/meetings';
import { RemoteStreams } from 'hooks/use-janus';
import { HeadsetVersion } from 'constants/constants';
import { MEETING, CONFERENCE } from 'constants/path';
import { QUERY } from 'constants/path';
import { Headset } from 'types';

const useGetHeadsetData = (headsetId: string | null): Headset | undefined => {
  const { pathname } = useLocation();
  const [query] = useSearchParams();
  const conferenceId = query.get(QUERY.conferenceId);
  const isMeetingPage = pathname === MEETING;
  const isConferencePage = pathname === CONFERENCE;

  const { data: headsetData } = useGetHeadset({ id: headsetId }, isConferencePage); // есть isConference === true эндпоинт скипается
  const { data: meetingData } = useGetMeeting({ id: conferenceId ?? undefined }, isMeetingPage); // есть isMeetingPage === true эндпоинт скипается

  if (headsetData) {
    return headsetData;
  } else if (meetingData) {
    return meetingData.headset;
  }
};

type Params = {
  myStream: MediaStream | null;
  remoteStreams: RemoteStreams;
  selectStreamId: string;
};

export const useGetVisibleCameraControls = ({ myStream, remoteStreams, selectStreamId }: Params) => {
  const [query] = useSearchParams();
  const headsetId = query.get(QUERY.headsetId);
  const { data: userData } = useGetMe();
  const headsetData = useGetHeadsetData(headsetId);
  const isMyHeadset = headsetId === userData?.headset?.id;
  const headsetStream = useMemo(
    () => Object.values(remoteStreams).find(({ display }) => display === headsetData?.key),
    [headsetData, remoteStreams],
  );

  const isVisibleCameraControls = useMemo(() => {
    if (myStream?.id === selectStreamId) {
      return isMyHeadset;
    }
    if (!headsetData) return false;
    return headsetStream?.stream?.id === selectStreamId;
  }, [selectStreamId, myStream, headsetStream, isMyHeadset, headsetData]);

  const isUserHeadset = !!headsetData?.as_user;
  const isVersionFirst = headsetData?.version === HeadsetVersion.v1_0 && !isMyHeadset;
  const isVersionSecond = headsetData?.version === HeadsetVersion.v2_0 && !isMyHeadset;
  const isVersionNewSecond = headsetData?.version === HeadsetVersion.v2_1 && !isMyHeadset;

  return {
    isVisibleCameraControls,
    headsetStream,
    isMyHeadset,
    isVersionFirst,
    isVersionSecond,
    isVersionNewSecond,
    isUserHeadset,
  };
};
