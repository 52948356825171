import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  Auth,
  Login,
  Registration,
  PasswordReset,
  ConfirmRegistration,
  ConfirmPasswordReset,
  SingUpExpert,
  SingUpEngineer,
} from 'pages';
import { AuthLayout } from 'components/layouts';
import {
  AUTH,
  LOGIN,
  REGISTRATION,
  PASSWORD_RESET,
  CONFIRM_REGISTRATION,
  CONFIRM_RESET_PASSWORD,
  SING_UP_EXPERT,
  SING_UP_ENGINEER,
} from 'constants/path';

export const PublicRoutes = () => {
  return (
    <Routes>
      {/*  Авторизация */}
      <Route path='*' element={<Navigate to={AUTH} />} />
      <Route path={AUTH} element={<AuthLayout />}>
        <Route path={AUTH} element={<Auth />} />
        <Route path={LOGIN} element={<Login />} />
        <Route path={REGISTRATION} element={<Registration />} />
        <Route path={SING_UP_EXPERT} element={<SingUpExpert />} />
        <Route path={SING_UP_ENGINEER} element={<SingUpEngineer />} />
        <Route path={PASSWORD_RESET} element={<PasswordReset />} />
      </Route>
      <Route path={`${CONFIRM_REGISTRATION}/:code`} element={<ConfirmRegistration />} />
      <Route path={`${CONFIRM_RESET_PASSWORD}/:code`} element={<ConfirmPasswordReset />} />
    </Routes>
  );
};
