import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PrivateCabinetHeader } from 'components/headers';
import { Tabs } from 'components/ui-kit';
import { QUERY } from 'constants/path';
import { ActiveConference } from './active-conference';
import { ArchiveConference } from './archive-conference';
import style from './home-dispatcher.module.scss';

const TABS_DATA = [
  { id: 'active', label: 'Активные' },
  { id: 'archive', label: 'Архив' },
];

export const HomeDispatcher = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const firstPageType = searchParams.get(QUERY.type);
  const [pageType, setPageType] = useState(firstPageType || TABS_DATA[0].id);

  useEffect(() => {
    setSearchParams({ [QUERY.type]: pageType });
  }, [pageType, setSearchParams]);

  return (
    <>
      <PrivateCabinetHeader title='Встречи' />
      <div className={style.page}>
        {/* TODO: добавить надпись, когда таблица пустая */}
        <Tabs classNames={{ container: style.tabs }} data={TABS_DATA} activeId={pageType} onChange={setPageType} />
        {pageType === 'active' && <ActiveConference />}
        {pageType === 'archive' && <ArchiveConference />}
      </div>
    </>
  );
};
