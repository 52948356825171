import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { PrivateCabinetHeader } from 'components/headers';
import { QUERY } from 'constants/path';
import { Player } from './player';
import TestPreviewImage2 from './test-preview-image-2.png';
import TestPreviewImage from './test-preview-image.png';
import style from './videos-page.module.scss';

const VIDEOS = [
  { id: 1, preview: TestPreviewImage, title: 'Видео 1' },
  { id: 2, preview: TestPreviewImage2, title: 'Видео 2' },
  { id: 3, preview: TestPreviewImage, title: 'Видео 3' },
  { id: 4, preview: TestPreviewImage2, title: 'Видео 4' },
  { id: 5, preview: TestPreviewImage, title: 'Видео 5' },
  { id: 6, preview: TestPreviewImage2, title: 'Видео 6' },
  { id: 7, preview: TestPreviewImage, title: 'Видео 7' },
  { id: 8, preview: TestPreviewImage, title: 'Видео 8' },
  { id: 9, preview: TestPreviewImage, title: 'Видео 9' },
  { id: 10, preview: TestPreviewImage2, title: 'Видео 10' },
  { id: 11, preview: TestPreviewImage, title: 'Видео 11' },
  { id: 12, preview: TestPreviewImage, title: 'Видео 12' },
  { id: 13, preview: TestPreviewImage2, title: 'Видео 13' },
  { id: 14, preview: TestPreviewImage, title: 'Видео 14' },
  { id: 15, preview: TestPreviewImage, title: 'Видео 15' },
  { id: 16, preview: TestPreviewImage, title: 'Видео 16' },
  { id: 17, preview: TestPreviewImage, title: 'Видео 17' },
  { id: 18, preview: TestPreviewImage, title: 'Видео 18' },
  { id: 19, preview: TestPreviewImage, title: 'Видео 19' },
  { id: 20, preview: TestPreviewImage, title: 'Видео 20' },
  { id: 21, preview: TestPreviewImage, title: 'Видео 21' },
  { id: 22, preview: TestPreviewImage, title: 'Видео 22' },
];

export const VideosPage = () => {
  const videoRef = useRef<HTMLDivElement>(null);
  const [params, setParams] = useSearchParams();
  const selectVideoId = Number(params.get(QUERY.videoId));

  useEffect(() => {
    const video = videoRef.current;
    if (!selectVideoId || !video) return;

    video.scrollIntoView();
  }, [selectVideoId]);

  return (
    <>
      <PrivateCabinetHeader title='Видео' onBack={() => {}} />
      <div className={style.videosFeed}>
        {VIDEOS.map(({ id, preview, title }, index) =>
          id === selectVideoId ? (
            <div className={style.player} key={id} ref={videoRef}>
              <div className={style.video}>
                <Player />
              </div>
              <div className={style.playerFooter}>
                <div className={style.title}>{title}</div>
                <Button onClick={() => setParams({})}>
                  <Close />
                </Button>
              </div>
            </div>
          ) : (
            <div className={style.item} key={id} onClick={() => setParams({ [QUERY.videoId]: id.toString() })}>
              <img src={preview} style={{ width: '100%' }} alt={title} />
              <div className={style.title}>{title}</div>
            </div>
          ),
        )}
      </div>
    </>
  );
};
