import React, { FC, useState } from 'react';
import { MyStream, RoleSelectionBlock, FailedBanner } from './';
import style from './preparation.module.scss';

type PreparationProps = {
  isActiveMicrophone: boolean;
  isActiveVideo: boolean;
  isHasAccessMicrophone: boolean;
  isHasAccessVideo: boolean;
  videoDeviceId: string;
  audioDeviceId: string;
  myStream: MediaStream | null;
  isButtonsDisabled?: boolean;
  setMyStream: React.Dispatch<React.SetStateAction<MediaStream | null>>;
  setVideoDeviceId: React.Dispatch<React.SetStateAction<string>>;
  setAudioDeviceId: React.Dispatch<React.SetStateAction<string>>;
  setIsActiveMeeting: React.Dispatch<React.SetStateAction<boolean>>;
  setIsHasAccessMicrophone: React.Dispatch<React.SetStateAction<boolean>>;
  setIsHasAccessVideo: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActiveMicrophone: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActiveVideo: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Preparation: FC<PreparationProps> = ({
  isActiveMicrophone,
  isActiveVideo,
  isHasAccessMicrophone,
  isHasAccessVideo,
  videoDeviceId,
  audioDeviceId,
  myStream,
  isButtonsDisabled,
  setMyStream,
  setVideoDeviceId,
  setAudioDeviceId,
  setIsActiveMeeting,
  setIsHasAccessMicrophone,
  setIsHasAccessVideo,
  setIsActiveMicrophone,
  setIsActiveVideo,
}) => {
  const [isFinishCreateStream, setIsFinishCreateStream] = useState(false);

  const isFailedCreateStream = isFinishCreateStream && !isHasAccessMicrophone && !isHasAccessVideo;

  return (
    <div className={style.container}>
      {!isFailedCreateStream && (
        <MyStream
          isActiveMicrophone={isActiveMicrophone}
          isActiveVideo={isActiveVideo}
          isHasAccessMicrophone={isHasAccessMicrophone}
          isHasAccessVideo={isHasAccessVideo}
          videoDeviceId={videoDeviceId}
          audioDeviceId={audioDeviceId}
          myStream={myStream}
          setMyStream={setMyStream}
          setVideoDeviceId={setVideoDeviceId}
          setAudioDeviceId={setAudioDeviceId}
          setIsHasAccessMicrophone={setIsHasAccessMicrophone}
          setIsHasAccessVideo={setIsHasAccessVideo}
          setIsActiveMicrophone={setIsActiveMicrophone}
          setIsActiveVideo={setIsActiveVideo}
          setIsFinishCreateStream={setIsFinishCreateStream}
        />
      )}
      {isFailedCreateStream && <FailedBanner />}
      <RoleSelectionBlock isButtonsDisabled={isButtonsDisabled} setIsActiveMeeting={setIsActiveMeeting} />
    </div>
  );
};
