import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
const userTimezone = dayjs.tz.guess();
/* export const getUserDate = (dateInUTC?: string | null) =>
  dateInUTC ? dayjs.utc(dateInUTC, 'DD.MM.YYYY').tz(userTimezone).format('DD.MM.YYYY') : ''; */

export const getUserTime = (timeInUTC?: string | null) =>
  timeInUTC ? dayjs.utc(timeInUTC, 'HH:mm').tz(userTimezone).format('HH:mm') : '';

export const getUserDateAndTime = (dateAndtimeInUTC?: string | null) =>
  dateAndtimeInUTC ? dayjs.utc(dateAndtimeInUTC, 'DD.MM.YYYY HH:mm').tz(userTimezone).format('DD.MM.YYYY HH:mm') : '';
