import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import { Provider } from 'components/provider';
import { SWRPropvider } from 'components/provider/swr-provider';
import { AppRoutes } from 'routes';
import './i18n/config';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
    <Provider>
      <SWRPropvider>
        <ToastContainer autoClose={5000} />
        <AppRoutes />
      </SWRPropvider>
    </Provider>
  </LocalizationProvider>,
);
