import { useEffect } from 'react';

type Ref = React.RefObject<HTMLElement> | React.RefObject<HTMLElement>[];

export const useOutsideClick = (ref: Ref, callback: () => void) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const refs = Array.isArray(ref) ? ref : [ref];
      //@ts-ignore
      const isClickInside = refs.some(ref => ref.current?.contains?.(e.target));
      if (!isClickInside) {
        callback();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, callback]);
};
