import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useScreenWakeLock } from 'hooks';
import { Preparation } from 'pages/meeting';
import { PrivateCabinetHeader } from 'components/headers';
import { PRIVATE_CABINET } from 'constants/path';
import style from './conference.module.scss';
import { VideoRoom } from './video-room';

export const Conference = () => {
  useScreenWakeLock();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isActiveMeeting, setIsActiveMeeting] = useState(false);

  const [myStream, setMyStream] = useState<MediaStream | null>(null);
  const [isActiveMicrophone, setIsActiveMicrophone] = useState(true);
  const [isActiveVideo, setIsActiveVideo] = useState(true);
  const [isHasAccessMicrophone, setIsHasAccessMicrophone] = useState(false);
  const [isHasAccessVideo, setIsHasAccessVideo] = useState(false);
  const [videoDeviceId, setVideoDeviceId] = useState('');
  const [audioDeviceId, setAudioDeviceId] = useState('');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (!id || id === 'null') {
      toast.error('Ошибка. У конференции отсутствует id');
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      myStream?.getTracks()?.forEach(track => {
        track.stop();
      });
    };
  }, [myStream]);

  const handleBack = () => {
    navigate(PRIVATE_CABINET);
  };

  return (
    <div className={style.container}>
      {/* TODO: вставлять название конференции */}
      <PrivateCabinetHeader title='Конференция' onBack={handleBack} />
      {isActiveMeeting ? (
        <VideoRoom
          isActiveMicrophone={isActiveMicrophone}
          isActiveVideo={isActiveVideo}
          videoDeviceId={videoDeviceId}
          audioDeviceId={audioDeviceId}
          isHasAccessMicrophone={isHasAccessMicrophone}
          isHasAccessVideo={isHasAccessVideo}
          myStream={myStream}
          setIsActiveMeeting={setIsActiveMeeting}
          setIsActiveMicrophone={setIsActiveMicrophone}
          setIsActiveVideo={setIsActiveVideo}
        />
      ) : (
        <Preparation
          isButtonsDisabled={hasError}
          isActiveMicrophone={isActiveMicrophone}
          isActiveVideo={isActiveVideo}
          isHasAccessMicrophone={isHasAccessMicrophone}
          isHasAccessVideo={isHasAccessVideo}
          videoDeviceId={videoDeviceId}
          audioDeviceId={audioDeviceId}
          myStream={myStream}
          setMyStream={setMyStream}
          setVideoDeviceId={setVideoDeviceId}
          setAudioDeviceId={setAudioDeviceId}
          setIsActiveMeeting={setIsActiveMeeting}
          setIsHasAccessMicrophone={setIsHasAccessMicrophone}
          setIsHasAccessVideo={setIsHasAccessVideo}
          setIsActiveMicrophone={setIsActiveMicrophone}
          setIsActiveVideo={setIsActiveVideo}
        />
      )}
    </div>
  );
};
