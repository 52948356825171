export const preferH264 = (sdp?: string) => {
  if (!sdp) return;
  const lines = sdp.split('\n');

  // Найти строку с H264
  const h264Index = lines.findIndex(line => line.includes('a=rtpmap') && line.includes('H264'));
  if (h264Index === -1) {
    return sdp; // Оставляем SDP без изменений
  }

  const firstVideoCodecIndex = lines.findIndex(line => line.startsWith('m=video'));
  if (firstVideoCodecIndex === -1) return sdp; // Если нет видео, выходим

  // Разбираем строку с кодеками
  const videoLineParts = lines[firstVideoCodecIndex].split(' ');
  const codecId = lines[h264Index].split(' ')[0].split(':')[1]; // ID кодека (например, "126")

  // Перемещаем H264 в начало списка
  const newCodecList = [codecId, ...videoLineParts.slice(3).filter(id => id !== codecId)];
  lines[firstVideoCodecIndex] = `${videoLineParts.slice(0, 3).join(' ')} ${newCodecList.join(' ')}`;

  return lines.join('\n');
};
