import React, { useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useGetMeeting } from 'api/endpoints/meetings';
import { useAddUsers } from 'api/endpoints/meetings/add-users';
import { useUpdateDatetime } from 'api/endpoints/meetings/update-datetime';
import { useUpdateStatus } from 'api/endpoints/meetings/update-status';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ConferenceUsersBlock } from 'components/conference-users-block';
import { PrivateCabinetHeader } from 'components/headers';
import { SelectMembersModal } from 'components/modals';
import { SelectDateAndTime } from 'components/select-date-and-time';
import { getUserDateAndTime, getUserTime } from 'utils/get-user-date-time';
import { PRIVATE_CABINET } from 'constants/path';
import { ExpertType, TypeChoice, User } from 'types';
import style from './dispatcher-edit-conference.module.scss';

dayjs.extend(utc);

const OPTIONS_TYPE_EXPERT: Record<ExpertType, string> = {
  electronics: 'Эксперт электронщик',
  mechanic: 'Эксперт механик',
  unknown: 'Не знаю какой эксперт',
};
const OPTIONS_TYPE_TIME: Record<TypeChoice, string> = {
  now: 'Горит - сейчас',
  today: 'Сегодня',
  three_days: 'В течении трех дней',
  custom: 'Выберите дату',
};

export const DispatcherEditConference = () => {
  const { id } = useParams();
  const { data } = useGetMeeting({ id });
  const { trigger: updateDatetime, isMutating: isMutatingDatetime } = useUpdateDatetime();
  const { trigger: updateStatus, isMutating: isMutatingStatus } = useUpdateStatus();
  const { trigger: addUsers, isMutating: isMutatingUsers } = useAddUsers();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [myUsers, setMyUsers] = useState<User[]>([]);

  const users = useMemo(() => {
    const savedUsers = data?.participants?.map(item => item.user_details) ?? [];
    return [...myUsers, ...savedUsers];
  }, [data, myUsers]);

  const handleBack = () => {
    navigate(PRIVATE_CABINET);
  };

  const disabled =
    !startDate ||
    !startTime ||
    !endTime ||
    !myUsers.length ||
    isMutatingDatetime ||
    isMutatingStatus ||
    isMutatingUsers;

  const handleSave = async () => {
    if (disabled) return;

    try {
      const myUsersIds = myUsers.map(item => item.id);

      await updateDatetime({
        id: id!,
        body: {
          meeting_date: startDate.format('DD.MM.YYYY'),
          meeting_start_time: startTime.utc().format('HH:mm'),
          meeting_end_time: endTime.utc().format('HH:mm'),
        },
      });
      await addUsers({ id: id!, body: { user_ids: myUsersIds } });
      await updateStatus({
        id: id!,
        body: {
          status: 'scheduled',
        },
      });
      toast.success('Встреча успешно назначена');
      handleBack();
    } catch (e) {
      toast.error(`${(e as { message: string }).message}`);
      console.error(e);
    }
  };

  const handleSaveMembers = (members: User[]) => {
    setMyUsers(members);
    setIsOpenModal(false);
  };

  return (
    <>
      <div className={style.page}>
        {/* TODO: добавить звездочки на обязательные поля */}
        <PrivateCabinetHeader title={`Назначение конференции ${id}`} onBack={handleBack} />
        <div className={style.content}>
          <div className={style.item}>
            <div className={style.label}>Заявка от:</div>
            {getUserDateAndTime(data?.created_at)}
          </div>
          <div className={style.item}>
            <div className={style.label}>Серийный номер станка</div>
            {data?.machine_serial}
          </div>
          <div className={style.item}>
            <div className={style.label}>Описание проблемы</div>
            {data?.description}
          </div>
          {!!data?.files.length && (
            <div className={style.item}>
              <div className={style.label}>Файлы</div>
              <div className={style.filesBlock}>
                {data.files.map(({ id, filename, file }) => (
                  <Link key={id} className={style.fileItem} to={file} target='_blank'>
                    <div className={style.name}>{filename}</div>
                  </Link>
                ))}
              </div>
            </div>
          )}
          <div className={style.item}>
            <div className={style.label}>Тип необходимого эксперта</div>
            {data?.expert_type && OPTIONS_TYPE_EXPERT[data.expert_type]}
          </div>
          <div className={style.item}>
            <div className={style.label}>Желаемое время для техподдержки</div>
            {data?.time_choice && OPTIONS_TYPE_TIME[data.time_choice]}
            <br />
            {!!data?.planned_start_date && `${data?.planned_start_date} - ${data?.planned_end_date}`}
            <br />
            {!!data?.planned_start_time &&
              `${getUserTime(data.planned_start_time)} - ${getUserTime(data?.planned_end_time)}`}
          </div>
          <div className={style.label}>Выберите дату и время для окозания услуг</div>
          <SelectDateAndTime
            required
            startDate={startDate}
            startTime={startTime}
            endTime={endTime}
            isVisibleEndDate={false}
            setStartDate={setStartDate}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
          />
          {/* Если со стороны диспетчера нет назначеных пользователей, то слхранение конференции должно быть невозможно */}
          {/* Обязательно должно быть дата, время и мои пользователи  */}
          <div>
            <ConferenceUsersBlock required users={users} />
            <Button variant='contained' startIcon={!myUsers.length && <Add />} onClick={() => setIsOpenModal(true)}>
              {myUsers.length ? 'Заменить участников' : 'Добавить участников'}
            </Button>
          </div>
          <div className={style.item}>
            <Button variant='contained' disabled={disabled} onClick={handleSave}>
              Назначить
            </Button>
          </div>
        </div>
      </div>
      {isOpenModal && <SelectMembersModal onClose={() => setIsOpenModal(false)} onSave={handleSaveMembers} />}
    </>
  );
};
