import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useGetMe, useLogout } from 'api';
import { useOutsideClick } from 'hooks/use-outside-click';
import { LanguageSelector } from 'components/language-selector';
import { StarsBlock } from 'components/stars-block';
import { Button } from 'components/ui-kit';
import { DEMO_USER_ID } from 'constants/constants';
import style from './information-block.module.scss';

type InformationBlockProps = {
  isVisible: boolean;
  buttonRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
};

export const InformationBlock = ({ isVisible, buttonRef, onClose }: InformationBlockProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'layouts.private-cabinet-layout' });
  const { data: user } = useGetMe();
  const { trigger } = useLogout();
  const handleLogout = () => {
    trigger();
  };

  const ref = useRef<HTMLDivElement>(null);
  const refs = useMemo(() => [ref, buttonRef], [buttonRef]);
  useOutsideClick(refs, onClose);

  const isDemoUser = user?.id === DEMO_USER_ID;

  return (
    <div className={cn(style.informationBlock, { [style.informationBlock__visible]: isVisible })} ref={ref}>
      {isDemoUser && (
        <>
          <StarsBlock size={20} />
          <div className={style.balance}>{t('yourBalance', { balance: '3000₽' })}</div>
        </>
      )}
      <Button classNames={{ button: style.logoutButton }} label={t('exit')} onClick={handleLogout} />
      <LanguageSelector classNames={{ block: style.languageSelector }} />
    </div>
  );
};
