import { PropsWithChildren, useContext } from 'react';
import { toast } from 'react-toastify';
import { fetcher } from 'api';
import { ActionsIsAuthContext } from 'contexts';
import { SWRConfig } from 'swr';
import { logout } from 'utils/logout';

export const SWRPropvider = ({ children }: PropsWithChildren) => {
  const { setIsAuth } = useContext(ActionsIsAuthContext);

  return (
    <SWRConfig
      value={{
        refreshInterval: process.env.NODE_ENV === 'development' ? 0 : 2000,
        shouldRetryOnError: false,
        //errorRetryCount: 3,
        fetcher,
        onError: (error, key) => {
          if (error.status === 401) {
            toast.error(`Срок авторизации истек`);
            logout(setIsAuth);
          } else {
            toast.error(error.message);
          }
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};
