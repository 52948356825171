import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetMeeting } from 'api/endpoints/meetings';
import { ConferenceUsersBlock } from 'components/conference-users-block';
import { PrivateCabinetHeader } from 'components/headers';
import { getUserDateAndTime, getUserTime } from 'utils/get-user-date-time';
import { PRIVATE_CABINET } from 'constants/path';
import { ExpertType, TypeChoice } from 'types';
import style from './conference-information.module.scss';

const OPTIONS_TYPE_EXPERT: Record<ExpertType, string> = {
  electronics: 'Эксперт электронщик',
  mechanic: 'Эксперт механик',
  unknown: 'Не знаю какой эксперт',
};
const OPTIONS_TYPE_TIME: Record<TypeChoice, string> = {
  now: 'Горит - сейчас',
  today: 'Сегодня',
  three_days: 'В течении трех дней',
  custom: 'Выберите дату',
};

export const ConferenceInformation = () => {
  const { id } = useParams();
  const { data } = useGetMeeting({ id });
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(PRIVATE_CABINET);
  };

  const users = data?.participants?.map(item => item.user_details) ?? [];

  return (
    <>
      <div className={style.page}>
        <PrivateCabinetHeader title={`Информация конференции ${id}`} onBack={handleBack} />
        <div className={style.content}>
          <div className={style.item}>
            <div className={style.label}>Заявка от:</div>
            {getUserDateAndTime(data?.created_at)}
          </div>
          <div className={style.item}>
            <div className={style.label}>Статус</div>
            {data?.status}
          </div>
          <div className={style.item}>
            <div className={style.label}>Серийный номер станка</div>
            {data?.machine_serial}
          </div>
          <div className={style.item}>
            <div className={style.label}>Описание проблемы</div>
            {data?.description}
          </div>
          <div className={style.item}>
            <div className={style.label}>Локация</div>
            {data?.location}
          </div>
          {!!data?.files.length && (
            <div className={style.item}>
              <div className={style.label}>Файлы</div>
              <div className={style.filesBlock}>
                {data.files.map(({ id, filename, file }) => (
                  <Link key={id} className={style.fileItem} to={file} target='_blank'>
                    <div className={style.name}>{filename}</div>
                  </Link>
                ))}
              </div>
            </div>
          )}
          <div className={style.item}>
            <div className={style.label}>Тип необходимого эксперта</div>
            {data?.expert_type && OPTIONS_TYPE_EXPERT[data.expert_type]}
          </div>
          <div className={style.item}>
            <div className={style.label}>Желаемое время для техподдержки</div>
            {data?.time_choice && OPTIONS_TYPE_TIME[data.time_choice]}
            <br />
            {!!data?.planned_start_date && `${data?.planned_start_date} - ${data?.planned_end_date}`}
            <br />
            {!!data?.planned_start_time &&
              `${getUserTime(data.planned_start_time)} - ${getUserTime(data?.planned_end_time)}`}
          </div>
          {data?.meeting_date && (
            <div className={style.item}>
              <div className={style.label}>Дата и время проведения обслуживания</div>
              {data?.meeting_date} в {getUserTime(data?.meeting_start_time)} - {getUserTime(data?.meeting_end_time)}
            </div>
          )}
          <div className={style.item}>
            <div className={style.label}>Гарнитура</div>
            {data?.headset?.name} - {data?.headset?.version}
          </div>
          <div className={style.item}>
            <div className={style.label}>Исполнитель</div>
            {data?.provider_details.company_name}
          </div>
          <div className={style.item}>
            <div className={style.label}>Заказчик</div>
            {data?.customer_details.company_name}
          </div>
          <ConferenceUsersBlock users={users} />
        </div>
      </div>
    </>
  );
};
