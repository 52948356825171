import React from 'react';
import cn from 'classnames';
import { MeetingStatus } from 'types';
import style from './conference-status.module.scss';

const STATUS_NAME = {
  draft: 'Редактируется',
  pending: 'На рассмотрении',
  scheduled: 'Назначена',
  in_progress: 'Идет',
  completed: 'Завершена',
  cancelled: 'Отменена',
};

type ConferenceStatusProps = {
  status: MeetingStatus;
};
export const ConferenceStatus = ({ status }: ConferenceStatusProps) => {
  return (
    <div className={style.container}>
      <div
        className={cn(style.status, {
          [style.status__draft]: status === 'draft',
          [style.status__pending]: status === 'pending',
          [style.status__scheduled]: status === 'scheduled',
          [style.status__inProgress]: status === 'in_progress',
          [style.status__completed]: status === 'completed',
          [style.status__cancelled]: status === 'cancelled',
        })}
      >
        {STATUS_NAME[status]}
      </div>
    </div>
  );
};
