import React, { useMemo } from 'react';
import { useGetMe } from 'api';
import { User } from 'types';
import style from './conference-users-block.module.scss';

type ConferenceUsersBlockProps = {
  users: User[];
  isHiddenOtherUsers?: boolean;
  required?: boolean;
};
export const ConferenceUsersBlock = ({ users, isHiddenOtherUsers, required }: ConferenceUsersBlockProps) => {
  const { data: dataMe } = useGetMe();

  const [myUsers, otherUsers] = useMemo(() => {
    if (!dataMe) return [[], []];
    const myParentId = dataMe.parent?.id ?? dataMe.id;
    const myUsers: User[] = [];
    const otherUsers: User[] = [];

    users.forEach(item => {
      const parentId = item.parent?.id ?? item.id;
      if (parentId === myParentId) {
        myUsers.push(item);
      } else {
        otherUsers.push(item);
      }
    });

    return [myUsers, otherUsers];
  }, [dataMe, users]);

  return (
    <div>
      {/* TODO: добавить возможночть назначения пользователей и отображение имеющихся */}
      <div className={style.title}>Участники{required && <span className={style.star}>*</span>}</div>
      <div>С моей стороны</div>
      <div>
        {myUsers.map(item => (
          <div key={item.id}>{item?.username}</div>
        ))}
      </div>
      {!isHiddenOtherUsers && (
        <>
          <div>Другие</div>
          <div>
            {otherUsers.map(item => (
              <div key={item.id}>{item?.username}</div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
