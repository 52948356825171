import { fetcher, Method, COLLEAGUES } from 'api';
import useSWR from 'swr';
//import useSWRInfinite from 'swr/infinite';
import { User } from 'types';

/* 
export type GetColleaguesParams = {
  limit?: number;
  offset?: number;
};

export type GetColleaguesResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: User[];
}; */

/* export type GetColleaguesParams = {
  limit?: number;
  offset?: number;
};
 */
export type GetColleaguesResponse = User[];

const getColleagues = async (path: string) => fetcher<GetColleaguesResponse>({ path, method: Method.GET });

export const useGetColleagues = () => {
  return useSWR(COLLEAGUES, getColleagues);
};

/* export const useGetColleagues = (params: GetColleaguesParams) => {
  //@ts-ignore
  const query = new URLSearchParams({ ...params });
  return useSWR(`${COLLEAGUES}?${query}`, getColleagues);
};

export const useInfinityGetColleagues = (params: Omit<GetColleaguesParams, 'offset'>) =>
  useSWRInfinite((pageIndex, previousPageData) => {
    const offset = pageIndex * (params.limit ?? 20);

    //@ts-ignore
    const query = new URLSearchParams({ ...params, offset });
    return `${COLLEAGUES}?${query}`;
  }, getColleagues); */
