import { getAuthorizationToken } from 'utils';
import { LOCAL_STORAGE_ACCEPT_LANGUAGE } from 'constants/local-storage';
import { Method } from './constants';

type FetcherParams = {
  path: string;
  method?: Method;
  body?: any;
  formData?: FormData;
  headers?: Record<string, string>;
};

export const fetcher = async <T>({ path, method = Method.GET, body, formData, headers }: FetcherParams): Promise<T> => {
  const authorizationToken = getAuthorizationToken();
  const acceptLanguage = localStorage.getItem(LOCAL_STORAGE_ACCEPT_LANGUAGE) ?? 'ru';

  const options: RequestInit = {
    method,
    headers: {
      Authorization: authorizationToken ? `Token ${authorizationToken}` : '',
      'Accept-Language': acceptLanguage,
      ...(formData ? {} : { 'Content-Type': 'application/json;charset=utf-8' }),
    },
  };

  if (body) options.body = JSON.stringify(body);
  if (formData) options.body = formData;
  if (headers) options.headers = { ...options.headers, ...headers };

  const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, options);
  const { status } = response;

  let data: T;
  try {
    data = await response.json();
  } catch (e) {
    data = {} as T;
  }

  if (!response.ok) {
    const errorMessage = (data as { message?: string }).message;

    if (status === 401) {
      const error = Object.assign(new Error(errorMessage), { status: 401 });
      throw error;
    }

    const error = new Error(errorMessage);
    throw error;
  }

  return data;
};
