import React, { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  PrivateCabinet,
  Meeting,
  HeadsetDetails,
  FinishMeeting,
  ConfirmRegistration,
  ConfirmPasswordReset,
  CreateConference,
  EditConference,
  ConferenceInformation,
  Conference,
  VideosPage,
} from 'pages';
import 'pages/conference';
import { Photos } from 'pages/photos';
import { PrivateCabinetLayout } from 'components/layouts';
import {
  PRIVATE_CABINET,
  MEETING,
  FINISH_MEETING,
  HEADSET_DETAILS,
  CONFIRM_REGISTRATION,
  CONFIRM_RESET_PASSWORD,
  CONFERENCE_INFORMATION,
  EDIT_CONFERENCE,
  CREATE_CONFERENCE,
  CONFERENCE,
} from 'constants/path';

export const PrivateRouter = () => {
  return (
    <Routes>
      <Route path='*' element={<Navigate to={PRIVATE_CABINET} />} />
      <Route path={PRIVATE_CABINET} element={<PrivateCabinetLayout />}>
        <Route path={PRIVATE_CABINET} element={<PrivateCabinet />} />
        <Route path={CREATE_CONFERENCE} element={<CreateConference />} />
        <Route path={`${EDIT_CONFERENCE}/:id`} element={<EditConference />} />
        <Route path={`${CONFERENCE_INFORMATION}/:id`} element={<ConferenceInformation />} />
        <Route path={`${CONFERENCE}/:id`} element={<Conference />} />

        <Route path='/private-cabinet/test-test' element={<VideosPage />} />
        <Route path='/private-cabinet/test-test-1' element={<Photos />} />

        <Route path={MEETING} element={<Meeting />} />
        <Route path={FINISH_MEETING} element={<FinishMeeting />} />
        <Route path={`${HEADSET_DETAILS}/:id`} element={<HeadsetDetails />} />
      </Route>
      <Route path={`${CONFIRM_REGISTRATION}/:code`} element={<ConfirmRegistration />} />
      <Route path={`${CONFIRM_RESET_PASSWORD}/:code`} element={<ConfirmPasswordReset />} />
    </Routes>
  );
};
