import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthHeader } from 'components/headers';
import { AUTH } from 'constants/path';

const EMAIL = 'personal@avatar-tech.ru';
export const SingUpEngineer = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(AUTH);
  };

  return (
    <>
      <AuthHeader title='Регистрация инженера-аватар' onBack={handleBack} />
      <div style={{ fontSize: '18px' }}>
        Заявки на вакансию 'инженер-аватар' отправляйте на
        <br />
        <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
      </div>
    </>
  );
};
