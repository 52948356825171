import { useContext } from 'react';
import { Method, LOGOUT, fetcher } from 'api';
import { ActionsIsAuthContext } from 'contexts';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { logout } from 'utils/logout';

export const _logout = async (path: string) => fetcher({ path, method: Method.POST });

export const useLogout = () => {
  const { mutate } = useSWRConfig();
  const { setIsAuth } = useContext(ActionsIsAuthContext);

  return useSWRMutation(LOGOUT, _logout, {
    onSuccess: () => {
      mutate(() => true, undefined, { revalidate: false });
      logout(setIsAuth);
    },
  });
};
