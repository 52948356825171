import React, { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, Button, TableBody } from '@mui/material';
import { useInfinityGetMeetings } from 'api/endpoints/meetings';
import { useIntersection } from 'hooks';
import { ConferenceStatus } from 'components/conference-status';
import { ConferenceTimeType } from 'components/conference-time-type';
import { ConferenceUsersTableBlock } from 'components/conference-users-block';
import { getUserDateAndTime, getUserTime } from 'utils/get-user-date-time';
import { ACTIVE_MEETING_STATUSES } from 'constants/constants';
import { CONFERENCE_INFORMATION, EDIT_CONFERENCE } from 'constants/path';
import style from './home-dispatcher.module.scss';

export const ActiveConference = () => {
  const { data: meetingsData, setSize } = useInfinityGetMeetings({ page_size: 20, status: ACTIVE_MEETING_STATUSES });

  const containerRef = useRef<HTMLDivElement>(null);
  const lastItemRef = useRef<HTMLTableRowElement>(null);
  const isVisible = useIntersection(lastItemRef, { root: containerRef.current });

  const meetings = useMemo(() => {
    if (!meetingsData) return [];
    return meetingsData.flatMap(page => page.results);
  }, [meetingsData]);

  useEffect(() => {
    if (!isVisible || !meetingsData || !meetingsData[meetingsData.length - 1]?.next) return;
    setSize(state => ++state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <TableContainer className={style.table} component={Paper}>
      <Table sx={{ minWidth: 1300 }}>
        <TableHead>
          <TableRow>
            <TableCell>Создана</TableCell>
            <TableCell>Статус желаемого обслуживания</TableCell>
            <TableCell>Назначенное дата и время</TableCell>
            <TableCell>Статус</TableCell>
            <TableCell>Тема</TableCell>
            <TableCell>Место</TableCell>
            <TableCell>Участники</TableCell>
            {/* TODO: раскомментить */}
            {/* <TableCell></TableCell> */}
            <TableCell></TableCell>
            <TableCell></TableCell>
            {/* <TableCell></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {meetings.map(
            (
              {
                id,
                planned_start_date,
                time_choice,
                planned_start_time,
                planned_end_time,
                machine_serial,
                location,
                status,
                participants,
                created_at,
                meeting_date,
                meeting_start_time,
                meeting_end_time,
              },
              index,
            ) => {
              const lastIndex = meetings.length - 1;
              const isLastItem = index === lastIndex;

              return (
                <TableRow
                  key={id}
                  ref={isLastItem ? lastItemRef : undefined}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{getUserDateAndTime(created_at)}</TableCell>
                  <TableCell>
                    <ConferenceTimeType type={time_choice} />
                  </TableCell>
                  <TableCell>
                    {status === 'draft' || status === 'pending'
                      ? '--'
                      : `${meeting_date} ${getUserTime(meeting_start_time)}-${getUserTime(meeting_end_time)}`}
                  </TableCell>
                  <TableCell>
                    <ConferenceStatus status={status} />
                  </TableCell>
                  <TableCell>{machine_serial}</TableCell>
                  <TableCell>{location}</TableCell>
                  <TableCell>
                    <ConferenceUsersTableBlock users={participants.map(({ user_details }) => user_details)} />
                  </TableCell>
                  <TableCell>
                    <Button size='small' component={Link} to={`${CONFERENCE_INFORMATION}/${id}`}>
                      Подробнее
                    </Button>
                  </TableCell>
                  {/* TODO: раскомментить */}
                  {/* TODO: добавить возможность закрыть любую конференцию */}
                  {/* <TableCell>
                        <Button size='small' color='error'>
                          Отменить
                        </Button>
                      </TableCell> */}
                  <TableCell>
                    {status === 'pending' && (
                      <Button size='small' component={Link} to={`${EDIT_CONFERENCE}/${id}`}>
                        Назначить
                      </Button>
                    )}
                  </TableCell>
                  {/* <TableCell>{status === 'in_progress' && <Button size='small'>Подключиться</Button>}</TableCell> */}
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
