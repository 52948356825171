import React, { useEffect, useState } from 'react';
import style from './text-loader.module.scss';

const MAX_NUMBER = 3;
export const TextLoader = () => {
  const [number, setNumber] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setNumber(state => (state === MAX_NUMBER ? 0 : state + 1));
    }, 400);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={style.loader}>
      Загрузка
      {Array(number)
        .fill('.')
        .flatMap(item => item)}
    </div>
  );
};
