import React, { useRef, useEffect, useState } from 'react';
import Hls from 'hls.js';
import frame1 from './frame-1.png';
import frame2 from './frame-2.png';
import frame3 from './frame-3.png';
import frame4 from './frame-4.png';
import frame5 from './frame-5.png';
import frame6 from './frame-6.png';
import frame7 from './frame-7.png';
import frame8 from './frame-8.png';
import frame9 from './frame-9.png';
import frame10 from './frame-10.png';

const TEST_VIDEO_URL = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8';
const VIDEO_LENGHT = 634; //секунд в 10:34
//потом будет больше фреймов, они будут приходить с бэка
const PREVIEW_FRAMES = [frame1, frame2, frame3, frame4, frame5, frame6, frame7, frame8, frame9, frame10];
const LAST_FRAME_INDEX = 9;

const MARGIN_HORIZONTAL = 16;
const PROGRESSBAR_HEIGHT = 23;

function formatTime(seconds: number) {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  const paddedSecs = secs < 10 ? `0${secs}` : secs;
  return `${mins}:${paddedSecs}`;
}

type VideoPlayerProps = { src: string };
const VideoPlayer = ({ src }: VideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, async () => {
        try {
          await video.play();
        } catch (e) {
          console.error(e);
        }
      });

      return () => hls.destroy();
      //TODO: надо?
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = src;
      video.addEventListener('loadedmetadata', async () => {
        try {
          await video.play();
        } catch (e) {
          console.error(e);
        }
      });
    }
  }, [src]);

  const [seconds, setSeconds] = useState(0);
  const [frameIndex, setFrameIndex] = useState(0);
  const [left, setLeft] = useState(0);
  const [visiblePreview, setVisiblePreview] = useState(false);

  const getLeftPreview = () => {
    //150 середина блока превью
    if (left < 150 + MARGIN_HORIZONTAL) {
      return MARGIN_HORIZONTAL;
    } else if (left > (videoRef.current?.offsetWidth ?? 0) - 150 - MARGIN_HORIZONTAL) {
      return (videoRef.current?.offsetWidth ?? 0) - 300 - MARGIN_HORIZONTAL;
    }

    return left - 150;
  };

  return (
    <div style={{ position: 'relative' }}>
      <video
        ref={videoRef}
        width='100%'
        height='100%'
        controls
        onMouseLeave={() => {
          setVisiblePreview(false);
        }}
        onMouseMove={e => {
          const video = videoRef.current;
          if (!video) return;
          const { offsetWidth, offsetHeight } = video;
          const { offsetX, offsetY } = e.nativeEvent;

          if (offsetHeight - offsetY > PROGRESSBAR_HEIGHT) {
            setVisiblePreview(false);
            return;
          }

          setVisiblePreview(true);

          if (offsetX < MARGIN_HORIZONTAL) {
            setLeft(0);
            setSeconds(0);
            setFrameIndex(0);
          } else if (offsetWidth - offsetX < MARGIN_HORIZONTAL) {
            setLeft(offsetWidth - MARGIN_HORIZONTAL);
            setSeconds(VIDEO_LENGHT);
            setFrameIndex(LAST_FRAME_INDEX);
          } else {
            const realVideoWidth = offsetWidth - MARGIN_HORIZONTAL * 2;
            const realLeft = offsetX - MARGIN_HORIZONTAL;
            const percent = realLeft / realVideoWidth;
            const seconds = percent * VIDEO_LENGHT;
            setSeconds(Math.round(seconds));
            setLeft(offsetX);
            const frameIndex = Math.floor(percent * 10);
            setFrameIndex(frameIndex > LAST_FRAME_INDEX ? LAST_FRAME_INDEX : frameIndex);
          }

          //left = 16px
          //right = 16px
          //bottom = 23px
        }}
      />
      {visiblePreview && (
        <div
          style={{
            width: '300px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            bottom: 40,
            left: getLeftPreview(),
          }}
        >
          <img src={PREVIEW_FRAMES[frameIndex]} width='100%' alt='' />
          <div style={{ marginTop: '12px', color: 'white', textShadow: '2px 2px 4px black' }}>
            {formatTime(seconds)}
          </div>
        </div>
      )}
    </div>
  );
};

export const Player = () => {
  return <VideoPlayer src={TEST_VIDEO_URL} />;
};
