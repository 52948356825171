import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AuthHeader } from 'components/headers';
import { Button } from 'components/ui-kit';
import { LOGIN, REGISTRATION, SING_UP_EXPERT, SING_UP_ENGINEER } from 'constants/path';
import style from './auth.module.scss';

export const Auth = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });
  const navigate = useNavigate();

  const handleClickLogin = () => {
    navigate(LOGIN);
  };

  const handleClickRegistration = () => {
    navigate(REGISTRATION);
  };

  return (
    <>
      <AuthHeader title={t('authorization')} />
      <Button classNames={{ button: style.button }} label={t('login')} onClick={handleClickLogin} />
      <Button classNames={{ button: style.button }} label={t('registration')} onClick={handleClickRegistration} />
      <div style={{ marginBottom: '8px' }}>
        <Link style={{ textDecoration: 'none', color: 'blue' }} to={SING_UP_EXPERT}>
          Хочу работать экспертом
        </Link>
      </div>
      <div>
        <Link style={{ textDecoration: 'none', color: 'blue' }} to={SING_UP_ENGINEER}>
          Хочу работать инженером-аватаром
        </Link>
      </div>
    </>
  );
};
