import React from 'react';
import { useInfinityGetSnapshot } from 'api';
import style from './photos.module.scss';

const ID = '0191b80c-b68f-71e7-ac8d-69fe99eb23bc';
const LIMIT = 20;
const FAKE_URL = 'https://i.pinimg.com/originals/c4/4a/74/c44a74f0553bcf43d178c64a65d52779.jpg';
const FAKE_URL_2 = 'https://avatars.mds.yandex.net/i?id=b940cae7a39c41edb2248674e63e05ba_l-8272366-images-thumbs&n=13';

export const Photos = () => {
  const { data, setSize } = useInfinityGetSnapshot({ headsetId: ID, limit: LIMIT });

  console.log(data);

  return (
    <div className={style.feed}>
      {data?.[0]?.results?.map(({ id, url, created_at }, index) => (
        <div key={id} className={style.item}>
          <img width='100%' src={index ? FAKE_URL_2 : FAKE_URL} alt={created_at} />
        </div>
      ))}
    </div>
  );
};
